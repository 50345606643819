import {
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButton,
  IonIcon,
} from "@ionic/react";
import {
  arrowBackOutline,
  eyeOutline,
  homeOutline,
  logOut,
} from "ionicons/icons";
import React, { useState } from "react";
import { useHistory } from "react-router";
import StandardAwesomeButton from "../atoms/StandardAwesomeButton";
import Title from "../atoms/Title";
import { useAuth0 } from "@auth0/auth0-react";
// import InlineProfilePicture from "./InlineProfilePicture";
// import UserModal from "./UserModal";

interface Props {
  systemname: string;
  pageTitle: string | Element | JSX.Element; // Really is a string or an Element...
  customButtonIcon?: any;
  customButtonUrl?: string;
  tabName?: string;
}

export default function PageHeader(props: Props) {
  const history = useHistory();
  const { logout } = useAuth0();

  // If tabName passed, set brower tab name
  if (props.tabName) {
    document.title = `BPX | ${props.tabName}`;
  }

  // const navContext = useContext(NavContext);
  // console.log("navContext", navContext);

  const [showModal, setShowModal] = useState(false);
  const [userObject, setUserObject] = useState({});

  let backButton: any = null;
  let homeButton: any = null;
  let hideButton: any = null;
  let logoutButton: any = null;
  // console.log(history);

  hideButton = (
    <IonButton slot="end">
      <IonIcon slot="start" icon={eyeOutline} /> Zen
    </IonButton>
  );

  logoutButton = (
    <IonButton onClick={() => logout()} slot="end">
      <IonIcon slot="start" icon={logOut} /> Logout
    </IonButton>
  );

  if (history.length > 0) {
    backButton = (
      <IonButton
        onClick={() => {
          console.log(history);

          history.goBack();
          // history.go(-1);

          // history.push(navContext?.routeInfo?.lastPathname ?? "/");
          // navContext.goBack();

          // Push last page to history

          // Get last path from history

          // goBack("/");
        }}
        slot="end"
      >
        <IonIcon icon={arrowBackOutline} />
      </IonButton>
      // <StandardAwesomeButton
      //   onClick={() => {
      //     history.goBack();
      //   }}
      //   text={<IonIcon icon={arrowBackOutline} />}
      // />
    );
  }

  if (props.customButtonUrl && props.customButtonIcon) {
    homeButton = (
      <IonButton
        routerLink={props.customButtonUrl}
        slot="end"
        // onClick={() => {
        //   history.push(props.customButtonUrl);
        // }}
      >
        <IonIcon icon={props.customButtonIcon} />
      </IonButton>
    );
  } else {
    homeButton = (
      <IonButton
        // routerLink="/"
        href="/"
        routerDirection="root"
        // onClick={() =>
        //   history.push("/")}

        slot="end"
      >
        <IonIcon icon={homeOutline} />
      </IonButton>
    );
  }

  return (
    <IonHeader>
      {/* <UserModal
        showModal={showModal}
        setShowModal={setShowModal}
        userObject={userObject}
      /> */}
      <IonToolbar mode="md" className="inverted">
        <IonTitle>
          <Title title={props.systemname} subTitle={props.pageTitle} />
        </IonTitle>
        {backButton}
        {homeButton}
        {/* {hideButton} */}
        {logoutButton}
        {/* <InlineProfilePicture
          setShowModal={setShowModal}
          setUserObject={setUserObject}
        /> */}
      </IonToolbar>
    </IonHeader>
  );
}
