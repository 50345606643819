import React, { useState, useEffect } from "react";
import JuiceContext from "../../../contexts/JuiceContext";
import "./BurstOfStars.css";

const BurstOfStars: React.FC = () => {
  const juiceContext: any = React.useContext(JuiceContext);
  const event = juiceContext.burstOfStarsEvent;

  const [coordinates, setCoordinates] = useState<{ x: number; y: number }>({
    x: 0,
    y: 0,
  });
  const [burst, setBurst] = useState(false);
  const [elements, setElements] = useState([] as any);

  useEffect(() => {
    if (burst) {
      // Start the burst animation
      const timeoutId = setTimeout(() => {
        if (juiceContext.burstOfStarsEvent?.clientX) {
          juiceContext.setBurstOfStarsEvent({});
        }
      }, 1000);

      console.log("Event", event);

      // Clean up the timeout when the component unmounts
      return () => clearTimeout(timeoutId);
    }
  }, [event]);

  // Trigger
  useEffect(() => {
    // Get the mouse coordinates when the button is clicked
    const { clientX, clientY } = event;

    // Trigger the burst animation
    setBurst(true);

    // Generate stars to burst
    const stars = [];
    for (let i = 0; i < event.count ?? 5; i++) {
      const randomX =
        Math.random() * window.innerWidth - window.innerWidth * 0.75;
      const randomY =
        Math.random() * window.innerHeight - window.innerHeight * 0.75;
      const randomDuration = Math.random() * 1000 - 50;

      stars.push({
        x: clientX,
        y: clientY,
        randomX,
        randomY,
        randomDuration,
      });
    }
    setElements(stars);
  }, [event]);

  //   console.log("Elements", elements);

  return event?.clientX ? (
    <>
      {elements.map((element: any, index: number) => {
        return (
          <span
            className="star"
            style={{
              left: element.x - 35,
              top: element.y - 35,
              // @ts-ignore
              "--x": `${element.randomX}px`,
              "--y": `${element.randomY}px`,
              //   animationDuration: `${element.randomDuration}ms`,
            }}
            key={index}
          >
            ⭐
          </span>
        );
      })}
    </>
  ) : null;
  //   return event?.clientX ? (
  //     <div className="burstContainer">
  //       <span
  //         className="star"
  //         style={{ left: coordinates.x, top: coordinates.y }}
  //       >
  //         ✨
  //       </span>
  //       <span
  //         className="star"
  //         style={{ left: coordinates.x - 50, top: coordinates.y - 50 }}
  //       >
  //         💫
  //       </span>
  //       <span
  //         className="star"
  //         style={{ left: coordinates.x + 50, top: coordinates.y - 50 }}
  //       >
  //         🌟
  //       </span>
  //       <span
  //         className="star"
  //         style={{ left: coordinates.x - 50, top: coordinates.y + 50 }}
  //       >
  //         ✨
  //       </span>
  //       <span
  //         className="star"
  //         style={{ left: coordinates.x + 50, top: coordinates.y + 50 }}
  //       >
  //         💥
  //       </span>
  //     </div>
  //   ) : null;
};

export default BurstOfStars;
