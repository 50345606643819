import {
  IonCard,
  IonCardContent,
  IonCardTitle,
  IonCol,
  IonContent,
  IonGrid,
  IonPage,
  IonRow,
} from "@ionic/react";
import React from "react";
import PageHeader from "../../../common/components/molecules/PageHeader";

export default function ExecutionHomePage() {
  // Check for login

  return (
    <IonPage>
      <PageHeader
        systemname="DreamHomePage"
        pageTitle="Project Name"
        tabName="DreamHomePage 🏠"
      />
      <IonContent className="tgImg">
        <IonGrid>
          <IonRow>
            <IonCol>
              <IonCard
                style={{
                  height: "25vh",
                }}
              >
                <IonCardContent>
                  <IonCardTitle>Research Center</IonCardTitle>
                </IonCardContent>
              </IonCard>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonCard
                style={{
                  height: "25vh",
                }}
              >
                <IonCardContent>
                  <IonCardTitle>Design Center</IonCardTitle>
                </IonCardContent>
              </IonCard>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonCard
                style={{
                  height: "25vh",
                }}
              >
                <IonCardContent>
                  <IonCardTitle>Project Center</IonCardTitle>
                </IonCardContent>
              </IonCard>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
}
