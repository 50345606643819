import React, { useEffect, useState } from "react";
// import CommonContext from "../../contexts/CommonContext";

interface Props {
  title: string;
  subTitle: string | Element | JSX.Element;
  useShadow?: boolean;
}

export default function Title(props: Props) {
  const [title, setTitle] = useState(
    <span style={{ fontWeight: "bold" }}>{props.title}</span>
  );

  // const commonContext: any = React.useContext(CommonContext);
  // const isMobile = commonContext?.isMobile;

  // DETECT IF MOBILE
  const isMobile = true;

  // TODO: There may be a more elegant soliution to this by utilizing ion items / labels / notes to get teh overlap right
  // Two use cases: Actual Titles (this makes sense) , but in items it should only do it at refresh... oh there it is

  // UseEffect to run whenever window changes size
  useEffect(() => {
    const handleResize = () => {
      // console.log("Resizing");

      // Get the width of the window
      // const width = window.innerWidth;
      if (window.innerWidth < 500 || isMobile === true) {
        // If on moblile, only show title
        setTitle(<span style={{ fontWeight: "bold" }}>{props.title}</span>);
      } else {
        setTitle(
          <span>
            <strong>{props.title}</strong> | {props.subTitle}
          </span>
        );
      }
    };

    // console.log("Mounting");

    handleResize();

    window.addEventListener("resize", handleResize);
  }, [props.title, props.subTitle, isMobile]);

  React.useEffect(() => {
    const shadow = props.useShadow
      ? "1px 2px 4px black"
      : "0px 0px 1px #000000";

    setTitle(
      <span>
        <strong
          style={{
            textShadow: "0px 0px 1px #000000",
          }}
        >
          {props.title}
        </strong>{" "}
        | {props.subTitle}
      </span>
    );
  }, [props.title, props.subTitle]);

  return title;
}
