import { useAuth0 } from "@auth0/auth0-react";
import {
  IonCard,
  IonCardContent,
  IonContent,
  IonPage,
  IonCardTitle,
  IonLoading,
  IonCardSubtitle,
  IonInput,
  IonItem,
} from "@ionic/react";
import React, { useContext, useEffect } from "react";
import StandardAwesomeButton from "../atoms/StandardAwesomeButton";

/**
 ** Basic Auth Logic
 * They will access this via a unique link
 * The first time the userId is passed as a urlQuery, it will be stored in local storage
 * Each time a page loads, it will check for the userId in local storage
 * If present, it will check for the last successful pin entry
 * If the pin entry is not present, it will prompt for a pin
 * If the pin entry is present, it will check to see if it's been within 7 days
 * If it's been within 7 days, it will allow access
 * If it's been more than 7 days, it will prompt for a pin
 *
 */

export default function Authwrapper({ children }: { children: any }) {
  const { user, loginWithPopup, loginWithRedirect, logout, isAuthenticated } =
    useAuth0();

  //TEMP
  console.log("user", user);

  // State for auth state and display
  const [isAuthorized, setIsAuthorized] = React.useState(false);
  const [showLoading, setShowLoading] = React.useState(false);
  const [contents, setContents] = React.useState(null as any);

  // TEMP TEST
  const [userId, setUserId] = React.useState(null as any);
  const [pin, setPin] = React.useState(null as any);

  //* Check PIN with Server
  // React.useEffect(() => {
  //   try {
  //     if (pin?.length === 4) {
  //       console.log("Checking Pin", pin);

  //       const body = {
  //         userId: userId,
  //         pin: pin,
  //       };

  //       // Send POST Request
  //       fetch("https://api.experimental.barnpros.app/externalUsers/checkPin", {
  //         method: "POST",
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //         body: JSON.stringify(body),
  //       }).then((response) => {
  //         if (response.status === 201) {
  //           console.log("Check Pin Response", response);
  //           // Set state
  //           setIsAuthorized(true);

  //           localStorage.setItem("lastPinEntry", new Date().toString());
  //           localStorage.setItem("userId", userId);

  //           // TODO: Get User here

  //           setShowLoading(false);

  //           setContents(children);
  //         } else {
  //           console.log("Check Pin Error", response);

  //           // Set state
  //           setIsAuthorized(false);

  //           localStorage.removeItem("lastPinEntry");
  //           setContents(pinNeeded);
  //         }
  //       });

  //       setPin(null);
  //     }
  //   } catch (error) {
  //     console.log("Check Pin Error:", error);
  //   }
  // }, [pin]);

  /**
   * Determine if Authorized or Not
   */
  React.useEffect(() => {
    // Get useerId from url query
    const urlParams = new URLSearchParams(window.location.search);
    const userId = urlParams.get("userId");
    console.log("userId", userId);
    setUserId(userId);

    try {
      const currentRoute = window.location.pathname;
      console.log("Verify Authorization", new Date().toLocaleTimeString());

      let authorized = false;

      // index always authorized
      if (currentRoute === "/") {
        authorized = true;
      }

      // Check localStorage for recent
      const lastPinEntry = localStorage.getItem("lastPinEntry");
      //   console.log("lastPinEntry", lastPinEntry);
      if (lastPinEntry) {
        const lastPinEntryDate = new Date(lastPinEntry);
        const now = new Date();
        const diff = Math.abs(now.getTime() - lastPinEntryDate.getTime());
        const diffDays = Math.ceil(diff / (1000 * 60 * 60 * 24));
        // console.log("diffDays", diffDays);
        if (diffDays < 7) {
          authorized = true;
        }

        // Set state
        setIsAuthorized(authorized);
      }

      if (isAuthenticated) {
        setContents(children);
      } else {
        setContents(
          <IonCard
            style={{
              marginTop: "47vh",
              maxWidth: "300px",
              marginLeft: "auto",
              marginRight: "auto",
              width: "fit-content",
            }}
          >
            <IonCardContent>
              <IonCardTitle>Authorization Error...</IonCardTitle>
            </IonCardContent>
          </IonCard>
        );
      }
    } catch (error) {
      console.log("Login Error:", error);
    }
  }, [pin, isAuthenticated]);

  const uniqueLinkNeeded = (
    <IonCardContent
      style={{
        textAlign: "center",
      }}
    >
      <IonCardTitle
        style={{
          textAlign: "center",
        }}
      >
        Please Log in to Continue to Barn Pros Dream Center
      </IonCardTitle>
      <br />
      {!user?.sub ? (
        <StandardAwesomeButton
          text="Log In"
          onClick={() => loginWithRedirect()}
          expand
        />
      ) : (
        <>
          <strong style={{ textAlign: "center" }}>
            You are logged in however, {user.given_name} {user.family_name} 😃
          </strong>
          <br />
          <br />
          <StandardAwesomeButton text="Test Logout" onClick={() => logout()} />
        </>
      )}
      <br />
      <br />
      <IonCardSubtitle
        style={{
          textAlign: "center",
        }}
      >
        Please call us at (866) 844-BARN for help 😃
      </IonCardSubtitle>
      <br />
      {userId}

      {/* <IonButton expand="block" onClick={() => loginWithPopup()}>
                Log In
              </IonButton> */}
    </IonCardContent>
  );

  const pinNeeded = (
    <IonCardContent>
      <IonCardTitle
        style={{
          textAlign: "center",
        }}
      >
        Please enter your 4 digit pin to Contine
      </IonCardTitle>
      <IonItem>
        <IonInput
          style={{
            textAlign: "center",
          }}
          maxlength={4}
          placeholder="Enter Pin"
          type="password"
          value={pin}
          // onIonChange={(e) => setPin(e.detail.value!)}
          onIonChange={(e) => loginWithRedirect()}
          autofocus={true}
        />
      </IonItem>
      <br />
      <IonCardSubtitle
        style={{
          textAlign: "center",
        }}
      >
        Please ask a BP Project Specialist or Fulfillmnent Specialist for
        Assitance
      </IonCardSubtitle>
      <br />

      {/* <IonButton expand="block" onClick={() => loginWithPopup()}>
                Log In
              </IonButton> */}
    </IonCardContent>
  );

  const loginPage = (
    <IonPage>
      <IonContent className="bgImg">
        <IonCard
          style={{
            marginTop: "40vh",
            maxWidth: "300px",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          {!userId ? uniqueLinkNeeded : pinNeeded}
        </IonCard>
      </IonContent>
    </IonPage>
  );

  //* Return either login page or auth error warning if auth0 is authorized
  try {
    if (isAuthenticated) {
      return (
        <div>
          <IonLoading
            isOpen={showLoading}
            message="Verifying Authorizaiton..."
            spinner="circular"
          />
          {contents}
        </div>
      );
    } else {
      // Use state for toast

      // TODO: Ensure no other pages are rendered until user is logged in

      return loginPage;
    }
  } catch (error) {
    console.log("AuthWrapper Error:", error);
    return loginPage;
  }
}
