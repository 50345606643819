import React, { useEffect, useState } from "react";

// Narry's Playground
const JuiceContext = React.createContext({});
export function JuiceProvider(props: any) {
  // State for Each Juice Animation
  const [burstOfStarsEvent, setBurstOfStarsEvent] = useState({} as any);

  return (
    <JuiceContext.Provider
      value={{
        // Burst of Stars
        burstOfStarsEvent,
        setBurstOfStarsEvent,
      }}
    >
      {props.children}
    </JuiceContext.Provider>
  );
}
export default JuiceContext;
