// @ts-ignore
import { IonApp, setupIonicReact } from "@ionic/react";
import React from "react";
import { Route, Router } from "react-router";
// import AppIndex from "./Common/components/organisms/AppIndex";
import { createBrowserHistory } from "history";
import HomePage from "./common/components/organisms/HomePage";
import ExecutionHomePage from "./executionCenter/components/organisms/ExecutionHomePage";
import Authwrapper from "./common/components/organisms/AuthWrapper";
import { Auth0Provider } from "@auth0/auth0-react";

function App() {
  // @ts-ignore
  const history = createBrowserHistory();

  // State
  // only for certain global states that don't make sense in context
  const [hideHeader, setHideHeader] = React.useState(false);

  setupIonicReact({
    // mode: "ios",
    rippleEffect: true,
    animated: true,
    swipeBackEnabled: true,
  });

  React.useEffect(() => {
    // Listen for alt+h keypress to trigger home button
    //     onKeyUp={(e) => {
    //       console.log("Key up triggered on Home Button");

    //       if (e.altKey && e.key === "h") {
    //         history.push(props.customButtonUrl ?? "");
    //       }
    // }
    // }
    document.addEventListener("keyup", (e) => {
      // if (e.altKey && e.key === "h") {
      //   history.push("/");
      // }
      // // z for zen mode
      // if (e.altKey && e.key === "z") {
      //   setHideHeader((prev: boolean) => !prev);
      // }
      // // p for projectCenter
      // if (e.altKey && e.key === "p") {
      //   history.push("/projectCenter");
      // }
      // // a for systemAdmin
      // if (e.altKey && e.key === "a") {
      //   history.push("/systemAdmin");
      // }
      // // m for map
      // if (e.altKey && e.key === "m") {
      //   history.push("/map");
      // }
    });

    return () => {
      document.removeEventListener("keyup", (e) => {
        console.log("Key up triggered on Home Button");

        if (e.altKey && e.key === "h") {
          history.push("/");
        }
      });
    };
  }, []);

  return (
    <IonApp>
      <Auth0Provider
        domain="barnpros-public.us.auth0.com"
        clientId="bQgilrZNLrcMW30Te3QJjXx1GcTkU5jh"
        audience="https://api.public.barnpros.app"
        redirectUri={`${window.location.origin}/`}
        useRefreshTokens={true}
        cacheLocation="localstorage"
      >
        <Authwrapper>
          <Router history={history}>
            {/* <Route path="/" component={AppIndex} /> */}
            <Route path="/" exact component={HomePage} />
            <Route path="/dreamHomePage" exact component={ExecutionHomePage} />
          </Router>
        </Authwrapper>
      </Auth0Provider>
    </IonApp>
  );
}

export default App;
