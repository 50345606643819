import React from "react";

import { AwesomeButton } from "react-awesome-button";
// import "react-awesome-button/dist/styles.css";

// Copied styles file so we can edit
import "./AwesomeButton.styles.css";
import { IonButton } from "@ionic/react";

interface Props {
  text: string | React.ReactNode; // Really should be called content probably...
  onClick?: Function;
  disabled?: boolean;
  style?: React.CSSProperties;
  delayOverride?: number; // Override the default 100ms delay to let animation show
  expand?: boolean; // Pass true to expand to 100% width
}

export default function StandardAwesomeButton(props: Props) {
  const { text, onClick, disabled, style, delayOverride, expand } = props;

  const buttonStyle = {
    ...style,
    width: expand ? "100%" : style?.width ?? "auto",
  };

  const compatibilityMode = localStorage.getItem("compatibilityMode");

  if (compatibilityMode !== "true") {
    return (
      <AwesomeButton
        type="primary"
        disabled={disabled}
        style={buttonStyle}
        onPress={(e: any) => {
          e.preventDefault();
          e.stopPropagation();

          if (onClick) {
            setTimeout(() => {
              onClick(e);
            }, delayOverride ?? 100);
          }
        }}
      >
        {text}
      </AwesomeButton>
    );
  } else {
    return (
      <IonButton
        disabled={disabled}
        style={buttonStyle}
        onClick={(e: any) => {
          e.preventDefault();
          e.stopPropagation();

          if (onClick) {
            setTimeout(() => {
              onClick(e);
            }, delayOverride ?? 100);
          }
        }}
      >
        {text}
      </IonButton>
    );
  }
}
