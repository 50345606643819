import {
  IonCardContent,
  IonCardTitle,
  IonContent,
  IonItem,
  IonLabel,
  IonLoading,
  IonModal,
  IonPage,
} from "@ionic/react";
import React from "react";
import StandardAwesomeButton from "../atoms/StandardAwesomeButton";
import { useAuth0 } from "@auth0/auth0-react";
import { add } from "ionicons/icons";
import { useHistory } from "react-router";

export default function HomePage() {
  const { user, logout, getAccessTokenSilently } = useAuth0();
  const history = useHistory();

  const addType = async (type: string) => {
    const token = await getAccessTokenSilently();

    // Save User type to Server
    const response = await fetch(
      `${process.env.REACT_APP_SERVER_URL}/users/addUserType`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ userType: type, userId: user?.sub }),
      }
    );

    console.log("response", response);
    setBpUser(await response.json());
  };

  const [showLoading, setShowLoading] = React.useState(true);
  const [bpUser, setBpUser] = React.useState({} as any);

  //* Fetch User on Load
  // TODO: Move to a Context
  React.useEffect(() => {
    const time = Date.now();
    getAccessTokenSilently().then((token) => {
      fetch(`${process.env.REACT_APP_SERVER_URL}/users/user/${user?.sub}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
        .then((res) => res.json())
        .then((res) => {
          console.log("User", res);
          setBpUser(res);
          setShowLoading(false);
        });
    });
  }, []);

  //* Redirect if User Type is Set
  React.useEffect(() => {
    if (bpUser.userType === "externalUser") {
      // window.location.href = "/dreamHomePage";
      history.push("/dreamHomePage");
    } else if (bpUser.userType === "professional") {
      // window.location.href = "/professionalsHome";
      history.push("/professionalsHome");
    }
  }, [bpUser]);

  return (
    <IonPage
      style={{
        width: "100vw",
        height: "100vh",
      }}
    >
      <IonLoading
        isOpen={showLoading}
        onDidDismiss={() => setShowLoading(false)}
        message={"Loading User Info..."}
      />
      <IonContent
        className="tgImg"
        style={{
          // Center children on screen
          width: "100vw",
          height: "100vh",
          display: "block",
          textAlign: "center",
        }}
      >
        {/* BARN PROS LOGO */}
        <img
          style={{
            // Center on screen
            width: "50%",
            maxWidth: "1000px",
            // margin: "auto",
            marginLeft: "auto",
            marginRight: "auto",
            marginTop: "25vh",
          }}
          src="https://bpx.sfo3.digitaloceanspaces.com/public/bpLogo.png"
          alt="Barn Pros Logo"
        />
        <br />
        <br />
        <p
          onClick={() => {
            logout();
          }}
        >
          Logged in as{" "}
          {user?.given_name ? (
            <>
              {user?.given_name} {user?.family_name}
            </>
          ) : (
            <>{user?.name}</>
          )}
        </p>
        <br />
        {/* <p>Please associate account with a unique invite link to continue</p> */}
        <strong>I am:</strong>
        <br />
        <br />
        <StandardAwesomeButton
          text="Looking to Research, Plan & Have my Dream Structure Built!"
          onClick={async () => {
            await addType("externalUser");
            // window.location.href = "/dreamHomePage";
          }}
          style={{ width: "40%" }}
          disabled={bpUser.userType}
        />
        &emsp;
        <StandardAwesomeButton
          text="A Planning / Design Professional or Builder"
          onClick={async () => {
            await addType("professional");
            window.location.href = "/professionalsHome";
          }}
          style={{ width: "40%" }}
          disabled={bpUser.userType}
        />
      </IonContent>
    </IonPage>
  );
}
